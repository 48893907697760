/* eslint-disable react/display-name */
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Tooltip, Switch, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HistoryIcon from '@material-ui/icons/History';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Pagination } from '@material-ui/lab';
import is from 'is_js';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import AdminService from '../services/AdminService';
import { PriceDisplay } from './atoms/PriceDisplay';
import CompanyAdminListModal from './modals/CompanyAdminListModal';
import { CountriesListModal } from './modals/CountriesListModal';
import TableIcons from './molecules/TableIcons';
import CompanyTypeLabel from './atoms/CompanyTypeLabel';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ReactCountryFlag from 'react-country-flag';
import { PointsBudgetsListModal } from './modals/PointsBudgetListModal';
import { FeatureCategoryNameEnum } from '../helpers/enums/FeatureCategoryNameEnum';
import FeaturesService from '../services/FeaturesService';
import { FeatureProviderNameEnum } from '../helpers/enums/FeatureProviderNameEnum';
import CompanySmartBookingsModal from './modals/CompanySmartBookingsModal';
import CompanyOwnOfficesModal from './modals/CompanyOwnOfficesModal';

const PAGE_SIZE = 30;
const STATUS_PROPERTIES = {
    1: 'pending_count',
    2: 'active_count',
    3: 'paused_count',
    4: 'suspended_count',
};

const COMPANY_TYPES = [
    'FIXED',
    'PREMIUM',
    'STANDARD',
    'BASIC',
    'LEGACY',
];

export const modelBackgrounds = {
    ['PREMIUM']: 'linear-gradient(345.06deg, #FC354C 0%, #0ABFBC 100%)',
    ['STANDARD']: 'linear-gradient(345.06deg, #FEAC5E 0%, #C779D0 50%, #4BC0C8 100%)',
    ['BASIC']: 'linear-gradient(165.06deg, #7E9DA3 0%, #E8F8FB 100%)',
    ['FIXED']: 'linear-gradient(45deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)',
    ['LEGACY']: 'lightgrey'
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '25px',
        marginBottom: '25px'
    },
    switchBase: {
        color: 'lightgray',
        '&$checked': {
            color: '#9d0ace',
        },
        '&$checked + $track': {
            backgroundColor: '#9d0ace',
        },
    },
}));

const Companies = () => {
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);
    const [companiesLoading, setCompaniesLoading] = useState(false);
    const [companyCount, setCompanyCount] = useState({});
    const [selectedStatus, setSelectedStatus] = useState(1);
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);
    const [clickedCompany, setClickedCompany] = useState({});
    const [companyType, setComnayType] = useState('<ALL>');
    const [countriesModalOpen, setCountriesModalOpen] = useState(false);
    const [companyAdminModalOpen, setCompanyAdminModalOpen] = useState(false);
    const [showPointsBudgetModal, setshowPointsBudgetModal] = useState(false);
    const [showSmartBookingsModal, setShowSmartBookingsModal] = useState(false);
    const [showOwnOfficesModal, setShowOwnOfficesModal] = useState(false);

    const getCompaniesByStatus = async (pageSize, pageNumber, companyName) => {
        setCompaniesLoading(true);
        try {
            const type = '<ALL>' == companyType ? null : companyType;
            let resp = await AdminService.getCompaniesByStatus(selectedStatus, type, pageSize, pageNumber, companyName);
            const { companies, ...counts } = resp.data;
            setCompanies(companies);
            setCompanyCount(counts);
            setCompaniesLoading(false);
        } catch (e) {
            setCompaniesLoading(false);
            console.log(e);
        }
    };

    const searchByName = (companyNameSearch) => {
        getCompaniesByStatus(PAGE_SIZE, selectedPage, companyNameSearch);
    };

    const refreshCompanies = async () => await getCompaniesByStatus(PAGE_SIZE, selectedPage);
    useEffect(() => {
        getCompaniesByStatus(PAGE_SIZE, 1);
        updatePaginator(companyCount[STATUS_PROPERTIES[selectedStatus]]);
    }, [companyType, selectedStatus]);

    const toPendingeCompany = async (companyId) => {
        await AdminService.updateCompanyStatus(companyId, 1);
        refreshCompanies();
    };

    const approveCompany = async (companyId) => {
        await AdminService.updateCompanyStatus(companyId, 2);
        refreshCompanies();
    };

    const deactivateCompany = async (companyId) => {
        await AdminService.updateCompanyStatus(companyId, 3);
        refreshCompanies();
    };

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        getCompaniesByStatus(PAGE_SIZE, value);
    };

    const updatePaginator = (totalCount) => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    const showPointsBudgetModalFn = (company) => {
        setClickedCompany(company);
        setshowPointsBudgetModal(true);
    };

    const showCountriesModal = (company) => {
        if (is.not.empty(company.countries)) {
            setClickedCompany(company);
            setCountriesModalOpen(true);
        }
    };

    const showAdminsModal = (company) => {
        setClickedCompany(company);
        setCompanyAdminModalOpen(true);
    };

    const toggleAcumCredits = async (companyId, isAccumulate) => {
        await AdminService.updateCompanyAcumCredit(companyId, isAccumulate);
        await refreshCompanies();
    };

    const toggleFeatureByCategoryName = async (companyId, categoryName, hasFeatures) => {
        await FeaturesService.toggleFeatureByCategoryName(companyId, hasFeatures, categoryName);
        await refreshCompanies();
    };

    const toggleCompanyAndCompanyCountryFeatures = async (companyId, body) => {
        await FeaturesService.toggleCompanyAndCompanyCountryFeatures(companyId, body);
        await refreshCompanies();
    };

    useEffect(() => {
        if (is.not.empty(clickedCompany)) {
            const cc = companies.find((c) => c.id === clickedCompany.id);
            cc && setClickedCompany(cc);
        }
        // eslint-disable-next-line
    }, [companies]);

    console.log(clickedCompany, 'la clicked');

    const CompanyActionButtons = ({ company }) => {

        const ActivateButton = () => <Tooltip title={'Activar Empresa'} className={'approveCompany'}>
            <IconButton color={'primary'} onClick={() => approveCompany(company.id)}>
                <ThumbUpIcon />
            </IconButton>
        </Tooltip>;

        const DeactivateButton = () => <Tooltip title={'Desactivar Empresa'} className={'deactivateCompany'}>
            <IconButton color={'primary'} onClick={() => deactivateCompany(company.id)}>
                <BlockIcon />
            </IconButton>
        </Tooltip>;

        const ToPendingButton = () => <Tooltip title={'Devolver a Pendiente'} className={'toPendingeCompany'}>
            <IconButton color={'primary'} onClick={() => toPendingeCompany(company.id)}>
                <HistoryIcon />
            </IconButton>
        </Tooltip>;

        switch (company.status) {
        case 1:
            return <Box>
                <ActivateButton />
                <DeactivateButton />
            </Box>;
        case 2:
            return <Box>
                <ToPendingButton />
                <DeactivateButton />
            </Box>;
        case 3:
            return <Box>
                <ToPendingButton />
                <ActivateButton />
            </Box>;
        case 4:
            return <Box>
                <ToPendingButton />
                <ActivateButton />
            </Box>;
        default:
            return null;
        }
    };

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <CountriesListModal companyId={clickedCompany.id} open={countriesModalOpen} setOpen={setCountriesModalOpen} refreshCompanies={refreshCompanies} />
            <CompanyAdminListModal company={clickedCompany} open={companyAdminModalOpen} setOpen={setCompanyAdminModalOpen} refreshCompanies={refreshCompanies} />
            <PointsBudgetsListModal company={clickedCompany} open={showPointsBudgetModal} setOpen={setshowPointsBudgetModal} refreshCompanies={refreshCompanies} />
            <CompanySmartBookingsModal company={clickedCompany} open={showSmartBookingsModal} setOpen={setShowSmartBookingsModal} />
            <CompanyOwnOfficesModal company={clickedCompany} open={showOwnOfficesModal} setOpen={setShowOwnOfficesModal} />

            <div style={{ marginLeft: isNarrowWidth ? '' : '177px' }}>
                <h1>Empresas</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant={selectedStatus === 1 ? 'contained' : 'outlined'} color={'primary'} onClick={() => setSelectedStatus(1)}>{`Pendientes (${companyCount.pending_count || 0})`}</Button>
                    <Button variant={selectedStatus === 2 ? 'contained' : 'outlined'} color={'primary'} onClick={() => setSelectedStatus(2)}>{`Activos (${companyCount.active_count || 0})`}</Button>
                    <Button variant={selectedStatus === 3 ? 'contained' : 'outlined'} color={'primary'} onClick={() => setSelectedStatus(3)}>{`Suspendidos (${companyCount.paused_count || 0})`}</Button>
                </div>
                <MaterialTable
                    title={statusLabels[selectedStatus] || ''}
                    icons={TableIcons}
                    isLoading={companiesLoading}
                    options={{
                        pageSize: PAGE_SIZE,
                        headerStyle: {
                            textAlign: 'center'
                        },
                        cellStyle: {
                            textAlign: 'center'
                        }
                    }}
                    columns={[
                        { title: 'ID', width: '10%', render: (b) => <div>{`#${b.id}`}</div> },
                        {
                            title: 'Nombre', width: '30%', field: 'name', render: (c) => <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <Avatar style={{ width: '50px', height: '50px', margin: '0 10px 0 0', border: '1px solid grey' }} src={c.avatar?.url} />
                                    <Box>{c.name}</Box>
                                </Box>
                                <CompanyTypeLabel company={c} key={c.name + c.id} />
                                <br />
                                <Box><b>Estado:</b> {statusLabels[c.status]}</Box>
                            </Box>
                        },
                        {
                            title: 'Más info', field: '', render: (c) => <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Box><b>Empleados:</b> {c.employees_count}</Box>
                                <br />
                                <Link onClick={() => showCountriesModal(c)} style={{ cursor: 'pointer' }}>Paises: {`${c.countries.map((c) => c.country.name).join(', ')}`}</Link>
                                <br />
                                {c?.type === 'FIXED' && <Link onClick={() => showPointsBudgetModalFn(c)} style={{ cursor: 'pointer', display: 'block', width: 130 }}>Presupuestos</Link>}
                                <br />
                                <Link onClick={() => showAdminsModal(c)} style={{ cursor: 'pointer' }}>Admins</Link>
                            </Box>
                        },
                        { title: 'Creación', width: '10%', field: 'created_date', render: (c) => c.created_date ? moment(c.created_date).format('LLL') : '-', sorting: false },
                        { title: 'Activación', width: '10%', field: 'activated_date', render: (c) => c.activated_date ? moment(c.activated_date).format('LLL') : '-', sorting: false },
                        { title: 'Desactivación', width: '10%', field: 'deactivated_date', render: (c) => c.deactivated_date ? moment(c.deactivated_date).format('LLL') : '-', sorting: false },
                        {
                            title: 'Acciones',
                            sorting: false,
                            field: '',
                            render: (rowData) => <div>
                                <CompanyActionButtons company={rowData} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    Acumulación
                                    <Switch
                                        classes={classes.switchBase}
                                        checked={rowData?.has_accumulated}
                                        onChange={() => toggleAcumCredits(rowData.id, !rowData?.has_accumulated)}
                                        name={'has_monthly_limit'}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    Social
                                    <Switch
                                        classes={classes.switchBase}
                                        checked={Boolean(rowData?.features.find((feature) => feature.featureProvider.providerName === FeatureProviderNameEnum.INTERNAL_SOCIAL))}
                                        onChange={(e) => toggleFeatureByCategoryName(rowData.id, FeatureCategoryNameEnum.SOCIAL, !e.target.checked)}
                                        name={'has_social'}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    Office SaaS
                                    <Switch
                                        classes={classes.switchBase}
                                        checked={Boolean(rowData?.features.find((feature) => feature.featureProvider.providerName === FeatureProviderNameEnum.INTERNAL_ANALYTICS))}
                                        onChange={(e) => toggleFeatureByCategoryName(rowData.id, FeatureCategoryNameEnum.ANALYTICS, !e.target.checked)}
                                        name={'has_analytics'}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    Flex
                                    <Switch
                                        classes={classes.switchBase}
                                        checked={Boolean(rowData?.features.find((feature) => feature.featureProvider.providerName === FeatureProviderNameEnum.INTERNAL_FLEX))}
                                        onChange={(e) => toggleCompanyAndCompanyCountryFeatures(rowData.id, {
                                            companyFeatures: [FeatureCategoryNameEnum.FLEX],
                                            companyCountryFeatures: [FeatureCategoryNameEnum.COWORKINGS],
                                            toggleActive: e.target.checked,
                                        })}
                                        name={'has_flex'}
                                    />
                                </div>

                                {rowData?.hasSmartBookings && (
                                    <Button
                                        variant={'contained'}
                                        onClick={() => {setShowSmartBookingsModal(true); setClickedCompany(rowData);}}
                                        color={'primary'}
                                    >
                                        Smart Bookings
                                    </Button>
                                )}

                                {rowData?.hasOwnOffices && (
                                    <Button
                                        variant={'contained'}
                                        onClick={() => {setShowOwnOfficesModal(true); setClickedCompany(rowData);}}
                                        color={'primary'}
                                    >
                                        Own Offices
                                    </Button>
                                )}
                            </div>
                        }
                    ]}
                    data={companies}
                    components={{
                        Pagination: (props) => (
                            <div className={classes.root}>
                                <Pagination style={{ width: '450px', margin: '0 auto' }} count={paginatorNumber} color={'primary'} page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                            </div>
                        ),
                        Toolbar: (props) => <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                            <Box style={{ fontSize: 24 }}>{props.title}</Box>
                            <FormControl style={{ width: '300px' }}>
                                <InputLabel shrink id={'company-type'} style={{}}>Tipo de Empresa:</InputLabel>
                                <Select
                                    labelId={'company-type'}
                                    id={'type'}
                                    value={companyType}
                                    onChange={(e) => setComnayType(e.target.value)}
                                    required
                                    label={'Tipo de Empresa'}
                                >
                                    <MenuItem value={'<ALL>'}>Todos</MenuItem>
                                    {COMPANY_TYPES.map((t) => <MenuItem key={t + '_type'} value={t}>{t}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <SearchInput searchFn={searchByName} />
                        </Box>
                    }}
                    detailPanel={(company) => <Box style={{ padding: 20, fontSize: 18 }}>
                        {!!company?.bookings_fee_percentage && <Box><b>Service Fee:</b> {company?.bookings_fee_percentage}%</Box>}
                        <Box><b>Max admins:</b> {company?.max_admins}</Box>
                        {!!company?.min_employees && <Box><b>Min employees: </b> <span>{company?.min_employees}</span></Box>}
                        {company?.min_employee_limit_in_usd && <Box><b>Min employees budget: </b> <PriceDisplay value={company?.min_employee_limit_in_usd} currency={'USD'} /></Box>}
                        {company?.employee_fee_in_usd && <Box><b>By employee fee: </b> <PriceDisplay value={company?.employee_fee_in_usd} currency={'USD'} /></Box>}
                        {company?.total_monthly_min_expend && <Box><b>Min monthly expend: </b> <PriceDisplay value={company?.total_monthly_min_expend} currency={'USD'} /></Box>}
                    </Box>}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsSelect: 'por página'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay empresas para mostrar.',
                        }
                    }}
                />
            </div>
        </Box>
    );
};

export default Companies;

export const SearchInput = ({ searchFn }) => {
    const [searchText, setSearchText] = useState('');

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            searchFn(searchText);
        }
    };

    return <Box>
        <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={keyPress}
            placeholder={'Buscar...'}
            autoFocus
            InputProps={{
                startAdornment: (
                    <InputAdornment position={'start'}>
                        <SearchOutlinedIcon />
                    </InputAdornment>
                ),
            }}
        />
    </Box>;
};

export const EmployeesListModal = ({ company, open, setOpen }) => {
    const [loading, setLoading] = useState(true);
    const normaliseUsed = (employee) => {
        if (+employee.limit_amount > 0)
            return ((+employee.used_amount || 0) * 100 / +employee.limit_amount).toFixed(0);
        return 0;
    };

    useEffect(() => {
        setLoading(is.empty(company?.countries || []));
    }, [company]);

    return <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>
            <b></b>
        </DialogTitle>
        <DialogContent>
            {loading ? <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></Box> :
                <MaterialTable
                    icons={TableIcons}
                    title={<Box style={{ display: 'flex', alignItems: 'center' }}>{'Empleados de '}<b style={{ margin: '0 5px' }}>{company.name}</b>{' en ' + countryCodes[company.countries?.[0]?.country?.name]} <ReactCountryFlag
                        svg
                        countryCode={company.countries?.[0]?.country?.name || ''}
                        style={{ marginLeft: 5, width: 32, height: 32 }}
                    /> {' (' + company.countries?.[0]?.employees_count + ')'}</Box>}
                    options={{ pageSize: 50, pageSizeOptions: [50, 100, 1000], sorting: true, thirdSortClick: false }}
                    columns={[
                        { title: 'ID', render: (b) => <div>{`#${b.id}`}</div> },
                        {
                            title: <b>Empleado</b>,
                            field: 'name',
                            render: (rowData) => <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt={`${rowData.user?.name} ${rowData.user?.lastname || ''}`} style={{ width: 40, borderRadius: '50%', marginRight: 5 }}>{`${rowData.user?.name?.[0] || ''}${rowData.user?.lastname?.[0] || ''}`}</Avatar>
                                <Box>{`${rowData.user?.name || ''} ${rowData.user?.lastname || ''}`}</Box>
                            </Box>,
                            editable: 'never',
                            customSort: (a, b) => {
                                const aName = `${a.user?.name || ''} ${a.user?.lastname || ''}`;
                                const bName = `${b.user?.name || ''} ${b.user?.lastname || ''}`;
                                if (aName < bName) return -1;
                                if (aName > bName) return 1;
                                return 0;
                            }
                        },

                        { title: <b>Posición</b>, field: 'position', emptyValue: '', editPlaceholder: 'Posición' },
                        { title: <b>Email</b>, field: 'email', editable: 'never' },
                        {
                            title: <b>Acumulación</b>,
                            sorting: false,
                            hidden: !company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            field: 'accumulate_credits',
                            render: (rowData) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Box>{company?.has_accumulated ? (rowData?.accumulate_credits ? 'Activado' : 'Desactivado') : 'Bloqueado por admin'}</Box>
                            </div>,
                        },
                        {
                            title: <b>Presupuesto mensual</b>,
                            hidden: !company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            field: 'current_month_limit',
                            render: (rowData) => <PriceDisplay value={rowData?.current_month_limit} currency={company.countries?.[0]?.currency} />,
                            emptyValue: '',
                            editPlaceholder: 'Posición',
                            customSort: (a, b) => +a?.current_month_limit - +b?.current_month_limit
                        },
                        {
                            title: <b>Limite Global</b>,
                            hidden: company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            field: 'current_month_limit',
                            render: (rowData) => (!company?.countries?.[0]?.has_employee_monthly_limit && !company?.countries?.[0]?.has_employee_global_limit) ? <b>ilimit</b> : <PriceDisplay value={company?.countries?.[0]?.employee_global_limit_amount} currency={company.countries?.[0]?.currency} />,
                            emptyValue: '',
                            editPlaceholder: 'Posición',
                            customSort: (a, b) => +a?.current_month_limit - +b?.current_month_limit
                        },
                        {
                            title: <b>Presupuesto acumulado</b>,
                            field: 'accumulated_credits_available',
                            hidden: !company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            render: (rowData) => <PriceDisplay value={rowData?.accumulated_credits_available} currency={company.countries?.[0]?.currency} />,
                            emptyValue: '',
                            editPlaceholder: 'Posición',
                            customSort: (a, b) => +a?.accumulated_credits_available - +b?.accumulated_credits_available
                        },
                        {
                            title: <b>Presupuesto mensual utilizado</b>, field: 'current_month_used_amount',
                            hidden: !company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            render: (rowData) => <Box style={{ color: normaliseUsed(rowData) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                                <Box style={{ marginRight: 5 }}>{normaliseUsed(rowData)}%</Box>
                                (<b><PriceDisplay value={+rowData.current_month_used_amount || 0} currency={company.countries?.[0]?.currency} /></b>)
                            </Box>,
                            editable: 'never',
                            customSort: (a, b) => +normaliseUsed(a) - +normaliseUsed(b),
                            defaultSort: 'desc'
                        },
                        {
                            title: <b>Utilizado en el mes</b>, field: 'current_month_used_amount',
                            hidden: company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            render: (rowData) => <Box style={{ color: normaliseUsed(rowData) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                                <b><PriceDisplay value={+rowData.current_month_used_amount || 0} currency={company.countries?.[0]?.currency} /></b>
                            </Box>,
                            editable: 'never',
                            customSort: (a, b) => +normaliseUsed(a) - +normaliseUsed(b),
                            defaultSort: 'desc'
                        },
                        {
                            title: <b>Presupuesto total</b>,
                            field: 'total_credits',
                            hidden: !company?.countries?.[0]?.has_employee_monthly_limit || company?.type === 'FIXED',
                            render: (rowData) => <PriceDisplay value={rowData?.total_credits} currency={company.countries?.[0]?.currency} />,
                            emptyValue: '',
                            editPlaceholder: 'Posición',
                            customSort: (a, b) => +a?.total_credits - +b?.total_credits
                        },
                    ]}
                    data={company?.countries?.[0]?.employees.filter((e) => [1, 2].includes(e.status))}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} empleado(s) selecionados'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay empleados para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtros'
                            },
                            editRow: {
                                deleteText: '¿Desea eliminar este empleado?'
                            }
                        }
                    }}
                />}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} color={'primary'}>Cerrar</Button>
        </DialogActions>
    </Dialog>;
};

export const BudgetsListModal = ({ company, open, setOpen, refreshCompanies }) => {
    const [loading, setLoading] = useState(false);

    const normaliseUsed = (budgets) => {
        if (+budgets.limit_amount > 0)
            return ((+budgets.used_amount || 0) * 100 / +budgets.limit_amount).toFixed(0);
        return 0;
    };

    const markAsPaid = (budgetId) => {
        setLoading(true);
        AdminService.markBudgetAsPaid(budgetId).then(async (resp) => {
            await refreshCompanies();
            setLoading(false);
        }).catch(console.log);
    };

    return <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>
            <b></b>
        </DialogTitle>
        <DialogContent>
            <MaterialTable
                icons={TableIcons}
                title={<Box style={{ display: 'flex', alignItems: 'center' }}>{'Historial de presupuestos de '}<b style={{ margin: '0 5px' }}>{company.name}</b>{' para '}<b style={{ margin: '0 5px' }}>{countryCodes[company.countries?.[0]?.country?.name]}</b> <ReactCountryFlag
                    svg
                    countryCode={company.countries?.[0]?.country?.name || ''}
                    style={{ marginLeft: 5, width: 32, height: 32 }}
                /> </Box>}
                options={{ pageSize: 10, pageSizeOptions: [10, 25, 50, 100], sorting: true, thirdSortClick: false }}
                columns={[
                    { title: 'ID', render: (b) => <div>{`#${b.id}`}</div> },
                    {
                        title: <b>Mes</b>,
                        render: (b) => `${moment(b.date).format('MM/YYYY')}`,
                        customSort: (a, b) => {
                            return moment(a.date).diff(moment(b.date), 'days');
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: <b>Utilizado de empleados</b>,
                        field: 'used_amount',
                        render: (rowData) => <Box style={{ color: normaliseUsed(rowData) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                            <b><PriceDisplay value={+rowData?.employee_used_amount || 0} currency={company.countries?.[0]?.currency} /></b>
                        </Box>
                    },
                    {
                        title: <b>Utilizado de la empresa</b>,
                        field: 'used_amount',
                        render: (rowData) => <Box style={{ color: normaliseUsed(rowData) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                            <b><PriceDisplay value={+rowData?.company_used_amount || 0} currency={company.countries?.[0]?.currency} /></b>
                        </Box>
                    },
                    {
                        title: <b>Utilizado total</b>,
                        field: 'used_amount',
                        render: (rowData) => <Box style={{ color: normaliseUsed(rowData) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                            <b><PriceDisplay value={+rowData.used_amount || 0} currency={company.countries?.[0]?.currency} /></b>
                        </Box>
                    },
                    {
                        title: <b>Pagado</b>,
                        field: 'paid',
                        render: (rowData) => {
                            return <>
                                {rowData.paid ?
                                    <CheckCircleIcon style={{
                                        paddingLeft: '30px',
                                        color: 'green',
                                        alignContent: 'center',
                                    }}
                                    />
                                    :
                                    (rowData.payment_error ? <CancelIcon style={{
                                        paddingLeft: '30px',
                                        color: 'red',
                                        alignContent: 'center',
                                    }}
                                    /> : '')
                                }
                            </>;
                        }
                    },
                    {
                        title: <b>Acciones</b>,
                        render: (rowData) => <Tooltip title={'Marcar como pagado'}>
                            <IconButton color={'primary'} onClick={() => markAsPaid(rowData.id)} disabled={loading}>
                                <ThumbUpIcon />
                            </IconButton>
                        </Tooltip>
                    },
                ]}
                data={company?.countries?.[0]?.budgets || []}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} color={'primary'}>Cerrar</Button>
        </DialogActions>
    </Dialog>;
};

function BooleanText(props) {
    const { value } = props;
    return <>{value ? 'true' : 'false'}</>;
}

const statusLabels = {
    1: 'Pendiente de Aprobación',
    2: 'Activo',
    3: 'Suspendido',
    4: 'Desactivado'
};

export const countryCodes = {
    'BD': 'Bangladesh',
    'BE': 'Bélgica',
    'BF': 'Burkina Faso',
    'BG': 'Bulgaria',
    'BA': 'Bosnia y Herzegovina',
    'BB': 'Barbados',
    'WF': 'Wallis y Futuna',
    'BL': 'San Bartolomé',
    'BM': 'Bermudas',
    'BN': 'Brunei',
    'BO': 'Bolivia',
    'BH': 'Bahrein',
    'BI': 'Burundi',
    'BJ': 'Benin',
    'BT': 'Bhután',
    'JM': 'Jamaica',
    'BV': 'Isla Bouvet',
    'BW': 'Botswana',
    'WS': 'Samoa',
    'BQ': 'Bonaire, San Eustaquio y Saba',
    'BR': 'Brasil',
    'BS': 'Bahamas',
    'JE': 'Jersey',
    'BY': 'Belarús',
    'BZ': 'Belice',
    'RU': 'Rusia',
    'RW': 'Ruanda',
    'RS': 'Serbia',
    'TL': 'Timor Oriental',
    'RE': 'Reunión',
    'TM': 'Turkmenistán',
    'TJ': 'Tayikistán',
    'RO': 'Rumania',
    'TK': 'Tokelau',
    'GW': 'Guinea-Bissau',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GS': 'Georgia del Sur y las Islas Sandwich del Sur',
    'GR': 'Grecia',
    'GQ': 'Guinea Ecuatorial',
    'GP': 'Guadalupe',
    'JP': 'Japón',
    'GY': 'Guyana',
    'GG': 'Guernsey',
    'GF': 'Guayana Francesa',
    'GE': 'Georgia',
    'GD': 'Granada',
    'GB': 'Reino Unido',
    'GA': 'Gabón',
    'SV': 'El Salvador',
    'GN': 'Guinea',
    'GM': 'Gambia',
    'GL': 'Groenlandia',
    'GI': 'Gibraltar',
    'GH': 'Ghana',
    'OM': 'Omán',
    'TN': 'Túnez',
    'JO': 'Jordania',
    'HR': 'Croacia',
    'HT': 'Haití',
    'HU': 'Hungría',
    'HK': 'Hong Kong',
    'HN': 'Honduras',
    'HM': 'Isla Heard e Islas McDonald',
    'VE': 'Venezuela',
    'PR': 'Puerto Rico',
    'PS': 'Territorio Palestino',
    'PW': 'Palau',
    'PT': 'Portugal',
    'SJ': 'Svalbard y Jan Mayen',
    'PY': 'Paraguay',
    'IQ': 'Irak',
    'PA': 'Panamá',
    'PF': 'Polinesia Francesa',
    'PG': 'Papua Nueva Guinea',
    'PE': 'Perú',
    'PK': 'Pakistán',
    'PH': 'Filipinas',
    'PN': 'Pitcairn',
    'PL': 'Polonia',
    'PM': 'San Pedro y Miquelón',
    'ZM': 'Zambia',
    'EH': 'Sáhara Occidental',
    'EE': 'Estonia',
    'EG': 'Egipto',
    'ZA': 'Sudáfrica',
    'EC': 'Ecuador',
    'IT': 'Italia',
    'VN': 'Vietnam',
    'SB': 'Islas Salomón',
    'ET': 'Etiopía',
    'SO': 'Somalia',
    'ZW': 'Zimbabwe',
    'SA': 'Arabia Saudita',
    'ES': 'España',
    'ER': 'Eritrea',
    'ME': 'Montenegro',
    'MD': 'Moldova',
    'MG': 'Madagascar',
    'MF': 'San Martín',
    'MA': 'Marruecos',
    'MC': 'Mónaco',
    'UZ': 'Uzbekistán',
    'MM': 'Myanmar',
    'ML': 'Malí',
    'MO': 'Macao',
    'MN': 'Mongolia',
    'MH': 'Islas Marshall',
    'MK': 'Macedonia',
    'MU': 'Mauricio',
    'MT': 'Malta',
    'MW': 'Malawi',
    'MV': 'Maldivas',
    'MQ': 'Martinica',
    'MP': 'Islas Marianas del Norte',
    'MS': 'Montserrat',
    'MR': 'Mauritania',
    'IM': 'Isla de Man',
    'UG': 'Uganda',
    'TZ': 'Tanzania',
    'MY': 'Malasia',
    'MX': 'México',
    'IL': 'Israel',
    'FR': 'Francia',
    'IO': 'Territorio Británico del Océano Índico',
    'SH': 'Santa Elena',
    'FI': 'Finlandia',
    'FJ': 'Fiji',
    'FK': 'Islas Malvinas',
    'FM': 'Micronesia',
    'FO': 'Islas Feroe',
    'NI': 'Nicaragua',
    'NL': 'Países Bajos',
    'NO': 'Noruega',
    'NA': 'Namibia',
    'VU': 'Vanuatu',
    'NC': 'Nueva Caledonia',
    'NE': 'Níger',
    'NF': 'Isla de Norfolk',
    'NG': 'Nigeria',
    'NZ': 'Nueva Zelanda',
    'NP': 'Nepal',
    'NR': 'Nauru',
    'NU': 'Niue',
    'CK': 'Islas Cook',
    'XK': 'Kosovo',
    'CI': 'Costa de Marfil',
    'CH': 'Suiza',
    'CO': 'Colombia',
    'CN': 'China',
    'CM': 'Camerún',
    'CL': 'Chile',
    'CC': 'Islas Cocos',
    'CA': 'Canadá',
    'CG': 'República del Congo',
    'CF': 'República Centroafricana',
    'CD': 'República Democrática del Congo',
    'CZ': 'República Checa',
    'CY': 'Chipre',
    'CX': 'Isla de Navidad',
    'CR': 'Costa Rica',
    'CW': 'Curaçao',
    'CV': 'Cabo Verde',
    'CU': 'Cuba',
    'SZ': 'Swazilandia',
    'SY': 'Siria',
    'SX': 'San Martín',
    'KG': 'Kirguistán',
    'KE': 'Kenya',
    'SS': 'Sudán del Sur',
    'SR': 'Suriname',
    'KI': 'Kiribati',
    'KH': 'Camboya',
    'KN': 'San Cristóbal y Nieves',
    'KM': 'Comoras',
    'ST': 'Santo Tomé y Príncipe',
    'SK': 'Eslovaquia',
    'KR': 'Corea del Sur',
    'SI': 'Eslovenia',
    'KP': 'Corea del Norte',
    'KW': 'Kuwait',
    'SN': 'Senegal',
    'SM': 'San Marino',
    'SL': 'Sierra Leona',
    'SC': 'Seychelles',
    'KZ': 'Kazajstán',
    'KY': 'Islas Caimán',
    'SG': 'Singapur',
    'SE': 'Suecia',
    'SD': 'Sudán',
    'DO': 'República Dominicana',
    'DM': 'Dominica',
    'DJ': 'Djibouti',
    'DK': 'Dinamarca',
    'VG': 'Islas Vírgenes Británicas',
    'DE': 'Alemania',
    'YE': 'Yemen',
    'DZ': 'Argelia',
    'US': 'Estados Unidos',
    'UY': 'Uruguay',
    'YT': 'Mayotte',
    'UM': 'Islas menores alejadas de los Estados Unidos',
    'LB': 'Líbano',
    'LC': 'Santa Lucía',
    'LA': 'Laos',
    'TV': 'Tuvalu',
    'TW': 'Taiwán',
    'TT': 'Trinidad y Tabago',
    'TR': 'Turquía',
    'LK': 'Sri Lanka',
    'LI': 'Liechtenstein',
    'LV': 'Letonia',
    'TO': 'Tonga',
    'LT': 'Lituania',
    'LU': 'Luxemburgo',
    'LR': 'Liberia',
    'LS': 'Lesotho',
    'TH': 'Tailandia',
    'TF': 'Territorios Australes Franceses',
    'TG': 'Togo',
    'TD': 'Chad',
    'TC': 'Islas Turcas y Caicos',
    'LY': 'Libia',
    'VA': 'Vaticano',
    'VC': 'San Vicente y las Granadinas',
    'AE': 'Emiratos Árabes Unidos',
    'AD': 'Andorra',
    'AG': 'Antigua y Barbuda',
    'AF': 'Afganistán',
    'AI': 'Anguila',
    'VI': 'Islas Vírgenes de los Estados Unidos',
    'IS': 'Islandia',
    'IR': 'Irán',
    'AM': 'Armenia',
    'AL': 'Albania',
    'AO': 'Angola',
    'AQ': 'Antártida',
    'AS': 'Samoa Americana',
    'AR': 'Argentina',
    'AU': 'Australia',
    'AT': 'Austria',
    'AW': 'Aruba',
    'IN': 'India',
    'AX': 'Islas Aland',
    'AZ': 'Azerbaiyán',
    'IE': 'Irlanda',
    'ID': 'Indonesia',
    'UA': 'Ucrania',
    'QA': 'Qatar',
    'MZ': 'Mozambique'
};


